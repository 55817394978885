import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import AuthorizationProvider from 'context/authorization';
import { Windmill } from '@windmill/react-ui';
import ExplodeTheme from './explode-theme';

const App = React.lazy(() => import('./app'));
const Login = React.lazy(() => import('./login'));

const Bootstrap = () => {
    return (
        <React.Suspense fallback={<h1>Loading...</h1>}>
            <AuthorizationProvider>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={App} />
                </Switch>
            </AuthorizationProvider>
        </React.Suspense>

    )
}

ReactDOM.render(<Windmill theme={ExplodeTheme} usePreferences><Router><Bootstrap /></Router></Windmill>, document.getElementById('root'));